<template>
  <v-container>
    <v-card
      width="800"
      class="pa-5"
    >
      <v-card-title>
        <h3>OAuth Scopes</h3>
      </v-card-title>
      <v-card-text>
        <p>
          The required permissions for this application are listed below.
        </p>
        <v-row dense>
          <v-col
            v-for="scope in allScopes"
            :key="scope.name"
            cols="12"
          >
            <v-row
              align="center"
              class="scope-row"
            >
              <v-col cols="6">
                <p class="font-weight-bold">
                  {{ scope.name }}
                </p>
              </v-col>
              <v-col
                cols="6"
                class="text-right"
              >
                <div class="permission-buttons">
                  <div
                    v-for="option in scope.permissions"
                    :key="option"
                    :class="[
                      'permission-button',
                      { 'selected': selectedScopes[scope.name] === option }
                    ]"
                  >
                    {{ option }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      allScopes: [
        { name: "Content", access: "modify" , permissions: ["None", "read-only", "modify"] },
        { name: "Checkout content", access: "None" , permissions: ["None", "read-only"] },
        { name: "Customers", access: "modify" , permissions: ["None", "read-only", "modify"] },
        { name: "Customers login", access: "None" , permissions: ["None", "login"] },
        { name: "Information & settings", access: "read-only" , permissions: ["None", "read-only", "modify"] },
        { name: "Marketing", access: "None" , permissions: ["None", "read-only", "modify"] },
        { name: "Orders", access: "modify" , permissions: ["None", "read-only", "modify"] },
        { name: "Order transactions", access: "read-only" , permissions: ["None", "read-only", "modify"] },
        { name: "Create payments", access: "None" , permissions: ["None", "create"] },
        { name: "Get payment methods", access: "None" , permissions: ["None", "read-only"] },
        { name: "Stored Payment Instruments", access: "None" , permissions: ["None", "read-only", "modify"] },
        { name: "Products", access: "read-only" , permissions: ["None", "read-only", "modify"] },
        { name: "Themes", access: "None" , permissions: ["None", "read-only", "modify"] },
        { name: "Carts", access: "None" , permissions: ["None", "read-only", "modify"] },
        { name: "Checkouts", access: "None" , permissions: ["None", "read-only", "modify"] },
        { name: "Sites & routes", access: "None" , permissions: ["None", "read-only", "modify"] },
        { name: "Channel settings", access: "None" , permissions: ["None", "read-only", "modify"] },
        { name: "Channel listings", access: "None" , permissions: ["None", "read-only", "modify"] },
        { name: "Storefront API tokens", access: "None" , permissions: ["None", "read-only"] },
        { name: "Storefront API customer impersonation tokens", access: "None" , permissions: ["None", "read-only"] },
        { name: "Store logs", access: "read-only" , permissions: ["None", "read-only"] },
        { name: "Store Locations", access: "read-only" , permissions: ["None", "read-only", "modify"] },
        { name: "Store Inventory", access: "modify" , permissions: ["None", "read-only", "modify"] },
        { name: "Fulfillment Methods", access: "modify" , permissions: ["None", "read-only", "modify"] },
        { name: "Order Fulfillment", access: "modify" , permissions: ["None", "read-only", "modify"] },
        { name: "Metafield Ownership", access: "manage" , permissions: ["None", "manage"] },
        { name: "Metafields Access", access: "full" , permissions: ["Standard", "full"] },
      ],
      permissionOptions: ["None", "read-only", "modify"],
      selectedScopes: {},
    };
  },
  created() {
    this.initializeSelectedScopes();
  },
  methods: {
    initializeSelectedScopes() {
      this.allScopes.forEach((scope) => {
        this.$set(this.selectedScopes, scope.name, scope.access || "None");
      });
    },
  },
};
</script>

<style scoped>
.permission-buttons {
  display: flex;
  justify-content: flex-end;
}

.permission-button {
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: #f0f0f0;
  color: #666;
  cursor: default;
  text-transform: capitalize;
}

.permission-button.selected {
  border: 1px solid #2196f3;
  background-color: #2196f3;
  color: #fff;
  font-weight: bold;
}

.permission-button.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.scope-row {
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
</style>
