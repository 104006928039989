<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    :title="'Close Batch '.concat(batch.id)"
  >
    <template
      v-if="batch"
      #content
    >
      <v-form>
        <v-container fluid>
          <v-row dense>
            <v-col cols="12">
              <div v-if="autoClose">
                Auto close batch on complete.
              </div>

              <div class="dialog-info">
                <div
                  v-if="!autoClose"
                  class="mt-3"
                >
                  Are you sure that you want to close batch
                  {{ batch.id }}?
                </div>
              </div>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="sort_by"
                label="Sort By"
                :items="sort_items"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="sort_order"
                label="Sort Order"
                :items="sort_order_items"
              />
            </v-col>

            </v-col>
            <v-col cols="12">
              <v-select
                v-model="print_type"
                label="Print Type"
                :items="print_types"
              />
            </v-col>

            <v-col
              v-if="print_type != 'label_only'"
              cols="12"
              text-center
            >
              <v-select
                v-model="packing_slip_template_id"
                name="template"
                label="Packing Slip Template"
                :items="packingSlipTemplates"
                item-text="name"
                item-value="id"
                :clearable="true"
                placeholder="Default Packing Slip"
                persistent-placeholder
              />
            </v-col>

    
            <v-col cols="12">
              <v-switch
                v-model="group_by_filter"
                label="Group by Filter"
              />
            </v-col>

            <v-col
              v-if="batch === null"
              cols="12"
            >
              <v-text-field
                v-model="name"
                label="Name (Optional)"
                clearable
                outlined
              />
            </v-col>
            <!-- <v-col cols="12">
              <v-checkbox
                :value="true"
                v-model="closeout"
                dense
                label="Create closeout sheet for batch"
                class="mt-1"
              >
              </v-checkbox>
            </v-col> -->
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="loading"
        color="primary"
        @click="closeBatch()"
      >
        Confirm
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    batch: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    },
    autoClose: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      closeout: true,
      loading: false,
      sort_items: [
        { text: 'Created At', value: 'id' },
        { text: 'Order ID', value: 'order_id' },
        { text: 'SKU', value: 'sku' },
        { text: 'Recipient', value: 'name' },
        { text: 'Import Order', value: 'row' },
        { text: 'Warehouse Location', value: 'warehouse_location' }
      ],
      sort_order_items: [
        { text: 'Descending', value: 'desc' },
        { text: 'Ascending', value: 'asc' }
      ],
      packing_slip_template_id: null,
      sort_by: 'id',
      sort_order: 'asc',
      print_type: 'label_only',
      print_types: [
        { text: 'Label', value: 'label_only' },
        { text: 'Label & Packing Slip', value: 'label_with_packing_slip' },
      ],
      group_by_filter: false,

    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    packingSlipTemplates() {
      return this.$store.getters['packingSlipTemplates/templates'];
    },
  },
  watch: {
    batch: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.batch) return;

        if (this.show && this.autoClose) {
          this.closeBatch();
        }
      }
    }
  },
  methods: {
    closeBatch() {
      this.loading = true;
      this.$http
        .post(`/batches/${this.batch.id}/close`, {
            sort_by: this.sort_by,
            sort_order: this.sort_order,
            group_by_filter: this.group_by_filter,
            type: this.print_type,
            packing_slip_template_id: this.packing_slip_template_id,
    })
        .then((response) => response.data)
        .then(() => {
          this.show = false;
          this.$store.dispatch('batches/getOpenBatches');
          this.$emit('update');
          if (this.$store.state.imp.batch) {
            this.$store.state.imp.batch.open = false
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
