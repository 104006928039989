<template>
  <SidePanelLayout :title="'Closeout '.concat(id)">
    <template #side-panel>
      <admin-view-shipment-details
        style="font-size: 14px"
        :shipments="main.selectedShipments"
      />
    </template>

    <template #page-links>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text v-if="closeout">
            <v-row>
              <!-- <v-col
                  v-if="closeout.errors && closeout.errors.length > 0"
                  cols="12"
                > -->

              <!--  -->
              <v-col cols="12">
                <div>
                  <h2># Shipments</h2>
                  <span v-if="closeout.shipments_count">
                    {{ closeout.shipments_count }}</span>
                  <span v-else>N/A</span>
                </div>
                <div v-if="auto_refresh === true">
                  Auto Refresh <v-icon>mdi-loading mdi-spin </v-icon>
                </div>
                <div v-else>
                  <v-btn
                    v-if="closeout.valid === 0"
                    color="primary"
                    @click="retry"
                  >
                    <v-icon
                      small
                      left
                    >
                      redo
                    </v-icon>
                    Retry
                  </v-btn>
                </div>
              </v-col>
              <!-- closeout.type_id === 2 where 2 is for postnl -->
              <v-col
                v-if="closeout.weight_lbs"
                cols="12"
              >
                <div>
                  <h2>Total Weight</h2>
                  <span>
                    {{ closeout.weight_lbs }} lbs</span>
                </div>
              </v-col>
              <v-col
                v-if="closeout.created_at"
                cols="12"
              >
                <h2>Created At</h2>
                {{ closeout.created_at | datetime_short }}
              </v-col>
              <v-col cols="12">
                <h2>Status</h2>
                <span v-if="closeout.valid == null">Processing</span>
                <span v-else-if="closeout.valid == 0">Error</span>
                <span v-else-if="closeout.valid == 1">Complete</span>
              </v-col>
              <v-col
                v-if="closeout.files && closeout.files.length > 0"
                cols="12"
              >
                <h2>Files</h2>
                <div
                  v-for="file in closeout.files"
                  :key="file"
                >
                  <a
                    href="javascript:void(0);"
                    @click="download(file)"
                  >{{
                    file | file_download
                  }}</a>
                </div>
              </v-col>
              <v-col cols="12">
                <v-card outlined>
                  <v-expansion-panels
                    accordion
                    flat
                  >
                    <v-expansion-panel v-if="closeout.errors">
                      <v-expansion-panel-header>
                        Errors
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <pre>{{ closeout.errors || json_pretty }}</pre>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="closeout.data">
                      <v-expansion-panel-header>Data</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <pre>{{ closeout.data || json_pretty }}</pre>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  @click="generateLabel()"
                >
                  Label
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="selected"
                  @click="removeShipments()"
                >
                  Remove
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <admin-shipment-table
          :admin-closeout-id="id"
          :url="'admin/shipments'"
        />
      </v-col>
    </template>
  </SidePanelLayout>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    id: {
      default: null,
      type: [Number, String]
    }
  },
  data() {
    return {
      closeout: null,
      auto_refresh: false,
      selected: false,
      counts: 0,
    };
  },
  watch: {
    '$store.state.main.selectedShipments': {
      handler(newValue) {
        this.counts = newValue.length;
        this.selected = this.counts <= 0;
      },
      deep: true
    }
  },
  computed: {
    ...mapState(['main'])
  },
  mounted() {
    this.getCloseout();
  },
  methods: {
    getCloseout() {
      this.$http
        .get(`/admin/closeouts/${this.id}`)
        .then((response) => response.data)
        .then((response) => {
          this.closeout = response;
          console.log(this.closeout.valid);
          if (this.closeout.valid === null) {
            this.auto_refresh = true;
            var self = this;
            setTimeout(function () {
              self.getCloseout();
            }, 5000);
          } else {
            this.auto_refresh = false;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    generateLabel() {
      this.$http({
        method: 'get',
        url: `/admin/closeouts/${this.id}/generate-label`
      })
        .then((response) => {
          this.downloadFileUrl(response.data, `Closeout_${this.id}.pdf`);
        })
        .catch(() => {
        })
        .finally(() => {
          this.generateLoading = false;
        });
    },
    download: function (data) {
      // var newfile;

      this.$http({
        method: 'post',
        url: '/admin/closeouts/download',
        data: {
          file: data
        }
      }).then((response) => {
        let filename = data.replace(/^.*[\\/]/, '');
        this.downloadFileUrl(response.data, filename, 'text/csv');
      });
    },
    retry: function () {
      this.$http({
        method: 'post',
        url: `/admin/closeouts/${this.id}/retry`
      }).then(() => {
        this.getCloseout();
      });
    },
    async removeShipments() {
      const isConfirm = await swal({
        title: 'Confirm Remove',
        text: 'Do you really want to remove '+this.counts+' Shipments?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        try {
          this.$http({
            method: 'post',
            url: `/admin/closeouts/remove`,
            data: {
              ids: this.$store.state.main.selectedShipments.map((x) => x.id)
            }
          })
              .then((response) => {
                if(response.data.success) {
                  this.successMessage('Shipments removed successfully!');
                  this.getCloseout();
                }
              });
        } catch (e) {
          console.log(e);
        }
      }
    },
  }
};
</script>
