<template>
  <FullPageLayout title="">
    <template #default>
      <v-row>
        <!-- Create User Button -->
        <v-col>
          <v-btn
            v-if="can('users.create')"
            outlined
            color="primary"
            @click="showCreateUserForm = true"
          >
            Create User
          </v-btn>

          <v-btn
            outlined
            color="primary"
            :disabled="selected.length === 0"
            class="my-3"
            @click="openBulkApprovalDialog"
          >
            Status
          </v-btn>
        </v-col>

        <!-- Search by Name/Email/ImporterNumber -->
        <v-col>
          <v-form @submit.prevent="submitSearch()">
            <v-container pa-0>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="search"
                    pt-0
                    label="Name/Email/ImporterNumber/WLR"
                    append-icon="search"
                    @input="submitSearch()"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-col>

        <!-- Search by Phone -->
        <v-col>
          <v-form @submit.prevent="submitSearch()">
            <v-container pa-0>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="phone"
                    pt-0
                    label="Phone"
                    append-icon="phone"
                    @input="submitSearch()"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-col>

        <!-- Filter by Approval Status -->
        <v-col>
          <v-select
            v-model="approval_status"
            :items="approval_statuses"
            label="Approval Status"
            @change="updateTable"
          />
        </v-col>
      </v-row>

      <!-- Data Table with Selection -->
      <v-data-table
        v-model="selected"
        :headers="headers"
        :loading="loading"
        item-key="id"
        :items="items"
        :options.sync="options"
        class="table-striped"
        :server-items-length="totalItems"
        :footer-props="{
          'items-per-page-options': [50, 100, 200, 500]
        }"
        :items-per-page="25"
        show-select
        @click:row="rowClick"
      >
        <!-- Credits Column -->
        <template #item.credits="{ item }">
          {{ item.credits | currency }}
        </template>

        <!-- Account Manager Column -->
        <template #item.manager_user_id="{ item }">
          <span v-if="item.manager">
            {{ item.manager.name }}
          </span>
        </template>

        <!-- Carm Agreement Column -->
        <template #item.carm_agreement="{ item }">
          <v-chip
            :style="{ color: '#000000', backgroundColor: item.carm_agreement ? '#cfffdb' : '#ffd1cf' }"
            small
          >
            {{ item.carm_agreement ? 'Completed' : 'Not Completed' }}
          </v-chip>
        </template>

        <!-- Created At Column -->
        <template #item.created_at="{ item }">
          {{ item.created_at | date_pretty }}
        </template>

        <!-- Action Column -->
        <template #item.action="{ item }">
          <v-btn
            v-if="item.return_agree"
            color="primary"
            @click.stop="download(item)"
          >
            <v-icon
              small
              dark
            >
              mdi-download
            </v-icon>
            POA
          </v-btn>
        </template>

        <!-- Approval Status Column (Non-interactive) -->
        <template #item.approval_status="{ item }">
          <div style="display: flex; align-items: center; width: 100%;">
            <!-- Approval Status Chip -->
            <v-chip
              :color="item.approval_status === 'Pending' ? 'warning' : item.approval_status === 'Approved' ? 'success' : 'error'"
              text-color="white"
              small
            >
              {{ item.approval_status }}
            </v-chip>

            <!-- Alert Icon for Approval Errors -->
            <div
              v-if="item.approval_errors?.length > 0 && item.approval_status === 'Pending'"
              style="margin-left: auto;"
            >
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    color="error"
                    v-on="on"
                  >
                    mdi-alert-circle-outline
                  </v-icon>
                </template>
                <div class="text-left">
                  <ul>
                    <li
                      v-for="error in item.approval_errors"
                      :key="error"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </div>
              </v-tooltip>
            </div>
          </div>
        </template>
      </v-data-table>

      <!-- Dialog for Bulk Approval Status Update -->
      <v-dialog
        v-model="bulkApprovalDialog"
        max-width="500"
      >
        <v-card>
          <v-card-title class="text-h5">
            Update Approval Status
          </v-card-title>
          <v-card-text>
            <v-select
              v-model="bulkApprovalStatus"
              :items="approval_statuses"
              label="Select New Approval Status"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="bulkApprovalDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="updateApprovalStatus()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Create User Component -->
      <create-user
        v-model="showCreateUserForm"
        @reloadUsers="updateTable"
      />
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  data() {
    return {
      // Approval Status Options
      approval_statuses: ['Pending', 'Approved', 'Rejected'],
      approval_status: null,

      // Search Parameters
      search: null,
      phone: null,

      // Data Table Headers
      headers: [
        { text: 'ID', align: 'left', value: 'id' },
        { text: 'Approval Status', value: 'approval_status' },
        { text: 'CARM Agreement', value: 'carm_agreement', sortable: true },
        { text: 'Email', value: 'email' },
        { text: 'Credits', value: 'credits' },
        { text: 'Name', value: 'name' },
        { text: 'Location', value: 'branch_location.name', sortable: false },
        { text: 'Region', value: 'region.name', sortable: false },
        { text: 'Business', value: 'business_name' },
        { text: 'Phone', value: 'phone' },
        { text: 'Account Manager', value: 'manager_user_id' },
        { text: 'Created At', value: 'created_at' },
        { text: '', value: 'action', sortable: false },
      ],

      // Data Table Options
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
      },

      // Data Properties
      totalItems: 0,
      items: [],
      loading: false,

      // Selection and Dialogs
      selected: [],
      bulkApprovalDialog: false,
      bulkApprovalStatus: null,

      // Other Properties
      timer: null,
      cancelSource: null,
      showCreateUserForm: false,
    };
  },

  watch: {
    '$route.query.action'() {
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true,
    },
  },

  mounted() {
    this.updateTable();
  },

  methods: {
    // Navigate to User Detail Page on Row Click
    rowClick(item) {
      this.$router.push({
        name: 'admin-user-show',
        params: {
          id: item.id,
        },
      });
    },

    // Submit Search with Debounce
    submitSearch() {
      clearTimeout(this.timer);
      const ms = 500;
      this.options.page = 1;
      this.timer = setTimeout(() => {
        this.updateTable();
      }, ms);
    },

    // Update Data Table
    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },

    // Fetch Data from API
    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        const params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          phone: this.phone,
          approval_status: this.approval_status,
        };

        this.$http
          .get('/admin/users', {
            params: params,
            cancelToken: this.cancelSource.token,
          })
          .then((response) => response.data)
          .then((response) => {
            const items = response.data;
            const total = response.total;
            const pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages,
            });
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },

    // Download POA Document
    download(user) {
      this.$http
        .post('/admin/users/poa', { user_id: user.id })
        .then((response) => {
          this.downloadFileUrl(response.data, (user.name + '_POA.pdf').replace(' ', '_'));
        })
        .catch(() => {
          swal({
            title: 'Error',
            text: 'Unable to find POA',
            icon: 'error',
          });
        });
    },

    // Open Bulk Approval Status Dialog
    openBulkApprovalDialog() {
      this.bulkApprovalStatus = null;
      this.bulkApprovalDialog = true;
    },

    // Update Approval Status for Selected Users
    updateApprovalStatus() {
      if (!this.bulkApprovalStatus) {
        this.$toast.error('Please select an approval status.');
        return;
      }

      this.loading = true;
      const userIds = this.selected.map((user) => user.id);

      // Send API request to update approval status for multiple users
      this.$http
        .patch('/admin/users/approval-status', {
          user_ids: userIds,
          approval_status: this.bulkApprovalStatus,
        })
        .then(() => {
          // Update local data
          this.items.forEach((item) => {
            if (userIds.includes(item.id)) {
              item.approval_status = this.bulkApprovalStatus;
            }
          });
          this.bulkApprovalDialog = false;
          this.selected = []; // Clear selection after update

          // close dialog and show success message
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
