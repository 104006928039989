<template>
  <v-col cols="12">
    <v-form>
      <v-row dense>
        <v-col cols="12">
          <h4>Address</h4>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.username"
            outlined
            dense
            label="Username"
            required
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <AccountSearch
            v-model="user.account_id"
            :label="'Account'"

            :outlined="true"
            :dense="true"
            :short="true"
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.first_name"
            outlined
            dense
            label="First Name"
            required
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.last_name"
            outlined
            dense
            label="Last Name"
            required
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.business_name"
            outlined
            dense
            label="Business Name"
            required
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.email"
            outlined
            dense
            label="E-mail"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.phone"
            v-mask="'###########'"
            v-validate="'required'"
            outlined
            dense
            :error-messages="errs.collect('phone')"
            label="Phone"
            data-vv-name="phone"
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.address1"
            outlined
            dense
            label="Address1"
            required
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.address2"
            outlined
            dense
            label="Address2"
            required
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.city"
            outlined
            dense
            label="City"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="user.province_code"
            v-validate="'required'"
            outlined
            dense
            :error-messages="errs.collect('province_code')"
            data-vv-name="province_code"
            :items="provinces"
            label="Province"
            item-text="name"
            item-value="code"
            :search-input.sync="searchInput2"
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.postal_code"
            v-mask="'A#A #A#'"
            v-validate="{
              required: true,
              regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
            }"
            outlined
            dense
            label="Postal Code"
            :error-messages="errs.collect('postal_code')"
            data-vv-name="postal_code"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="user.country_code"
            v-validate="'required'"
            outlined
            dense
            :error-messages="errs.collect('country_code')"
            data-vv-name="country_code"
            :items="countries"
            label="Country"
            item-text="name"
            item-value="code"
            :search-input.sync="searchInput"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <h4>Contact Details</h4>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.contact_phone"
            v-mask="'###########'"
            v-validate="''"
            outlined
            dense
            :error-messages="errs.collect('contact_phone')"
            label="Phone"
            data-vv-name="contact_phone"
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.contact_email"
            outlined
            dense
            label="E-mail"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <h4>Account Settings</h4>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-checkbox
            v-model="user.verified"
            outlined
            dense
            label="Email Verified"
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-checkbox
            v-model="user.account_setup"
            outlined
            dense
            label="Account Setup"
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-checkbox
            v-model="user.credit_restriction"
            outlined
            dense
            label="Credit Restriction"
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-checkbox
            v-model="user.enable_fraud_checking"
            outlined
            dense
            label="Enable Fraud Checking"
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-checkbox
            v-model="user.locked"
            outlined
            dense
            label="Account Locked"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <h4>Approval</h4>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="user.approval_status"
            outlined
            dense
            label="Approval Status"
            :items="approval_statuses"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <!-- approved by -->
          <strong>Approved By: </strong><br>{{ user.approved_by?.email }}
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <strong>Approval Errors: </strong><br>
          {{ user.approval_errors?.join(', ') }}
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <strong>Approval At: </strong><br>
          {{ user.approval_at | datetime_pretty }}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <h4>Shipping Options</h4>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-checkbox
            v-model="user.enable_usps_media"
            outlined
            dense
            label="Enable USPS Media Mail"
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-checkbox
            v-model="user.enable_usps_library"
            outlined
            dense
            label="Enable USPS Library Mail"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-checkbox
            v-model="user.enable_third_party_postage"
            outlined
            dense
            label="Enable Third Party Postage"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <h4>Operations Settings</h4>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-checkbox
            v-model="user.check_fba"
            outlined
            dense
            label="Check Fba"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-checkbox
            v-model="user.check_non_fba"
            outlined
            dense
            label="Check Non-Fba"
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-checkbox
            v-model="user.deliver_supplies"
            outlined
            dense
            label="Deliver Supplies"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-checkbox
            v-model="user.deliver_returns"
            outlined
            dense
            label="Deliver Returns"
          />
        </v-col>
      </v-row>
     
      <v-row dense>
        <v-col cols="12">
          <h4>UTM Info</h4>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.utm_source"
            outlined
            dense
            label="UTM Source"
            readonly
            disabled
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.utm_medium"
            outlined
            dense
            label="UTM Medium"
            readonly
            disabled
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="user.utm_campaign"
            outlined
            dense
            label="UTM Campaign"
            readonly
            disabled
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          text-center
        >
          <v-btn
            v-if="can('users.edit')"
            color="primary"
            :disabled="loading"
            :loading="loading"
            @click="updateUser()"
          >
            Update
            <v-icon dark>
              mdi-save
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-col>
</template>

<script>
import constants from '@/constants/index';
export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      tabSelected: null,
      loading: false,
      branch: null,
      searchInput: null,
      searchInput2: null,
      countries: [
        {
          name: 'Canada',
          code: 'CA'
        }
      ],
      default_location_list: constants.DEFAULT_PICKUP_LOCATIONS,
      provinces: constants.PROVINCES,
      accountManagers: [],
      auto_request: 0,
      approval_statuses: [
        'Pending',
        'Approved',
        'Rejected',
      ],
    };
  },
  watch: {
    user: {
      handler: function (val, oldVal) {
        if (Object.keys(oldVal).length !== 0) {
          this.disabled = false;
        }
      },
      deep: true
    }
  },
  async mounted() {
    this.getAccountManagers();
    const setting = this.user.settings.find(
      (s) => s.key == 'return_auto_request'
    );
    this.auto_request = setting ? setting.value : 0;
  },
  methods: {
    getAccountManagers() {
      this.$http.get('/admin/managers').then(
        (response) => {
          this.accountManagers = response.data;
        },
        () => {
          // error callback
        }
      );
    },
    updateUser() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.loading = true;
          this.disabled = true;
          this.user.auto_request_returns = this.auto_request;
          this.$http
            .post(`/admin/users/${this.user.id}/settings`, this.user)
            .then((res) => {
              if (res.data.success) {
                swal({
                  title: 'Success',
                  text: 'User updated successfully',
                  icon: 'success'
                });
              } else {
                swal({
                  title: 'Error',
                  text: res.data.errors
                    ? res.data.errors
                    : 'Something went wrong.',
                  icon: 'error'
                });
              }
            })
            .finally(() => {
              this.loading = false;
              this.disabled = false;
            });
        } else {
          swal({
            title: 'Error',
            text: 'Some of the form fields are invalid.',
            icon: 'error'
          });
        }
      });
    }
  }
};
</script>
