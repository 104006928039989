<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-card
          outlined
          class="pa-5"
        >
          <v-data-table
            :headers="headers"
            :items="webhooks"
            item-value="id"
            class="elevation-1"
            dense
            outlined
          >
            <template #top>
              <v-toolbar flat>
                <v-btn
                  color="primary"
                  @click="openImportDialog"
                >
                  Import JSON
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primary"
                  @click="openAddWebhookDialog"
                >
                  Add Webhook
                </v-btn>
              </v-toolbar>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-icon
                small
                @click="editWebhook(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                class="ml-2"
                @click="deleteWebhook(item)"
              >
                mdi-delete
              </v-icon>
              <v-icon
                small
                class="ml-2"
                @click="exportWebhook(item)"
              >
                mdi-export
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogMode === 'add' ? 'Add Webhook' : 'Edit Webhook' }}</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="9">
                <v-text-field
                  v-model="webhookForm.url"
                  label="Endpoint URL"
                  required
                />
              </v-col>
              <v-col cols="3">
                <v-select
                  label="Add Placeholder"
                  :items="['{order_id}', '{display_order_id}']"
                  outlined
                  dense
                  @change="appendToUrl"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <p class="text-caption">
                  Note: You can use placeholders to set dynamic data within the URL
                  using the
                  Add Placeholder dropdown.<br>Example: https://example.com/webhook/{order_id}
                </p>
              </v-col>
            </v-row>

            <v-select
              v-model="webhookForm.request_type"
              label="Request Type"
              :items="['GET', 'POST', 'PUT', 'DELETE']"
              required
            />

            <v-select
              v-model="webhookForm.event"
              label="Event"
              :items="['Order Fulfilled']"
              required
            />

            <v-select
              v-model="webhookForm.store_id"
              label="Store"
              :items="userStores"
              item-text="name"
              item-value="id"
              required
            />

            <v-btn
              text
              color="primary"
              @click="addEntry('param')"
            >
              Add Param
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="addEntry('header')"
            >
              Add Header
            </v-btn>

            <v-row
              v-for="(entry, index) in webhookForm.entries"
              :key="index"
              class="mb-3"
            >
              <v-col cols="2">
                <!-- Type Indicator -->
                <span class="font-weight-bold">{{ entry.type === 'param' ? 'Param' : 'Header' }}</span>
              </v-col>
              <v-col cols="4">
                <!-- Key Field -->
                <v-text-field
                  v-model="entry.key"
                  label="Key"
                  :error-messages="entry.type === 'header' && !isValidHeaderName(entry.key) ? ['Invalid header name'] : []"
                  required
                  @blur="entry.type === 'header' && !isValidHeaderName(entry.key) && $toast.error('Invalid header name. Allowed characters: letters, numbers, dashes (-), underscores (_)')"
                />
              </v-col>
              <v-col cols="4">
                <!-- Value Field for Params -->
                <v-select
                  v-if="entry.type === 'param'"
                  v-model="entry.value"
                  label="Value"
                  :items="paramOptions.concat(['Custom Value'])"
                  required
                />

                <!-- Custom Value Input for Params -->
                <v-text-field
                  v-if="entry.value === 'Custom Value' && entry.type === 'param'"
                  v-model="entry.customValue"
                  label="Custom Value"
                  required
                />

                <!-- Value Input for Headers -->
                <v-text-field
                  v-else-if="entry.type === 'header'"
                  v-model="entry.value"
                  label="Value"
                  required
                />
              </v-col>
              <v-col cols="2">
                <!-- Remove Entry Button -->
                <v-btn
                  icon
                  @click="removeEntry(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="saveWebhook"
          >
            Save
          </v-btn>
          <v-btn
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmDialog.visible"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirm Deletion
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete the webhook for "{{ confirmDialog.webhook?.url || '' }}"? This
          action cannot
          be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red"
            text
            @click="confirmRemove"
          >
            Delete
          </v-btn>
          <v-btn
            text
            @click="confirmDialog.visible = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="importDialog.visible"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Import Webhook JSON</span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="importDialog.json"
            label="Paste JSON here"
            rows="10"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="handleImport"
          >
            Import
          </v-btn>
          <v-btn
            text
            @click="importDialog.visible = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="exportDialog.visible"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Export Webhook JSON</span>
        </v-card-title>
        <v-row>
          <v-col
            cols="10"
            style="text-align: center;margin:auto"
          >
            <p class="text-caption">
              Copy the JSON below to export the webhook configuration. This will not include
              user
              specific information (stores, etc). You will have to set these manually after import.
            </p>
          </v-col>
        </v-row>
        <v-card-text>
          <v-textarea
            v-model="exportDialog.json"
            label="Webhook JSON"
            rows="10"
            readonly
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="copyToClipboard"
          >
            Copy
          </v-btn>
          <v-btn
            text
            @click="exportDialog.visible = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    data() {
        return {
            confirmDialog: {
                visible: false,
                webhook: null,
            },
            headers: [
                { text: "ID", value: "id" },
                { text: "Endpoint URL", value: "url" },
                { text: "Request Type", value: "request_type" },
                { text: "Event", value: "event" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            webhooks: [], // Initialize as an empty array
            dialog: false,
            dialogMode: "add",
            webhookForm: {
                url: "",
                request_type: "POST",
                event: "Order Fulfilled",
                store_id: null,
                entries: [],
            },
            userStores: [
                // Initialize as an empty array with a null field for all stores
                { id: null, name: "All Stores" },
            ],
            paramOptions: [
                "order_id",
                "status",
                "tracking_code",
                "tracking_url",
                "shipment",
                'weight',
                'weight_unit',
                'items',
                'rate',
                'rate_total',
                'delivery_days',
            ],
            importDialog: {
                visible: false,
                json: "",
            },
            exportDialog: {
                visible: false,
                json: "",
            },
        };
    },
    mounted() {
        this.fetchWebhooks();
        this.fetchStores();
    },
    methods: {
        exportWebhook(webhook) {
            // Create a sanitized copy of the webhook, excluding `id` and `store_id`
            const { id, store_id, ...exportableWebhook } = webhook;

            // Convert the sanitized object to JSON
            this.exportDialog.json = JSON.stringify(exportableWebhook, null, 2);
            this.exportDialog.visible = true;
        },
        copyToClipboard(text) {
            if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(text)
                    .then(() => console.log('Copied successfully'))
                    .catch(err => console.error('Error copying to clipboard:', err));
            } else {
                // Fallback for unsupported environments
                const tempInput = document.createElement('textarea');
                tempInput.value = text;
                document.body.appendChild(tempInput);
                tempInput.select();
                try {
                    document.execCommand('copy');
                    console.log('Copied using fallback');
                } catch (err) {
                    console.error('Fallback copy failed: ', err);
                }
                document.body.removeChild(tempInput);
            }
        },
        async handleImport() {
            try {
                const importedWebhooks = JSON.parse(this.importDialog.json);

                // Normalize input: Convert single object to array
                const webhooks = Array.isArray(importedWebhooks)
                    ? importedWebhooks
                    : [importedWebhooks];

                // Validate and sanitize webhooks
                const sanitizedWebhooks = webhooks.map(webhook => ({
                    url: webhook.url || "",
                    request_type: webhook.request_type || "POST",
                    event: webhook.event || "Order Fulfilled",
                    store_id: webhook.store_id || null,
                    entries: webhook.entries || [],
                }));

                for (const webhook of sanitizedWebhooks) {
                    this.webhookForm = webhook;
                    this.dialogMode = "add"; // Ensure new webhooks are added
                    await this.saveWebhook();
                }

                this.$notify({ type: "success", message: "Webhooks imported successfully!" });
                this.importDialog.visible = false;
            } catch (error) {
                this.$notify({ type: "error", message: "Invalid JSON. Please try again." });
            }
        },

        openImportDialog() {
            this.importDialog.json = "";
            this.importDialog.visible = true;
        },
        appendToUrl(placeholder) {
            if (!this.webhookForm.url.includes(placeholder)) {
                this.webhookForm.url += this.webhookForm.url.endsWith("/")
                    ? placeholder
                    : `/${placeholder}`;
            }
        },
        isValidHeaderName(name) {
            const headerRegex = /^[a-zA-Z0-9\-_]+$/; // Allow only alphanumeric, dashes, and underscores
            return headerRegex.test(name) && name.length <= 256; // Maximum length of 256 characters
        },
        parseWebhooks(webhooks) {
            const processWebhook = (webhook) => {
                webhook.entries = Object.entries(webhook.params || {}).map(([key, value]) => {
                    const isHeader = key.startsWith("header_");
                    const actualKey = isHeader ? key.replace("header_", "") : key;

                    return isHeader
                        ? { type: "header", key: actualKey, value }
                        : {
                            type: "param",
                            key: actualKey,
                            value: this.paramOptions.includes(value) ? value : "Custom Value",
                            customValue: this.paramOptions.includes(value) ? "" : value,
                        };
                });
                return webhook;
            };

            return Array.isArray(webhooks)
                ? webhooks.map(processWebhook)
                : processWebhook(webhooks);
        },
        async fetchWebhooks() {
            const response = await this.$http.get("/custom-webhooks");
            this.webhooks = await this.parseWebhooks(response.data.webhooks);
        },
        async fetchStores() {
            try {
                // Dispatch the action to fetch stores
                await this.$store.dispatch('userStores/getStores');

                // Retrieve store options from the Vuex getter
                const storeOptions = this.$store.getters['userStores/getStoreOptions'];

                // Map the store options to the desired structure
                storeOptions.forEach(store => {
                    this.userStores.push({
                        id: store.id,
                        name: store.nickname ? store.nickname : store.identifier,
                    });
                });
            } catch (error) {
                console.error("Error fetching stores:", error);
            }
        },
        openAddWebhookDialog() {
            this.dialogMode = "add";
            this.webhookForm = {
                url: "",
                request_type: "POST",
                event: "Order Fulfilled",
                store_id: null,
                entries: [],
            };
            this.dialog = true;
        },
        editWebhook(webhook) {
            this.dialogMode = "edit";
            this.webhookForm = JSON.parse(JSON.stringify(webhook)); // Deep copy to avoid mutation issues
            this.dialog = true;
        },
        deleteWebhook(webhook) {
            this.confirmDialog.webhook = webhook;
            this.confirmDialog.visible = true;
        },
        async confirmRemove() {
            try {
                const webhook = this.confirmDialog.webhook;
                await this.$http.delete(`/custom-webhooks/${webhook.id}`);
                this.webhooks = this.webhooks.filter((w) => w.id !== webhook.id);
                this.$notify({ type: "success", message: "Webhook deleted successfully!" });
            } catch (error) {
                this.$notify({ type: "error", message: "Failed to delete webhook. Please try again." });
            } finally {
                this.confirmDialog.visible = false;
            }
        },
        addEntry(type) {
            this.webhookForm.entries.push({ type, key: "", value: "", customValue: "" });
        },
        removeEntry(index) {
            this.webhookForm.entries.splice(index, 1);
        },
        async saveWebhook() {
            const payload = { ...this.webhookForm };
            payload.params = this.webhookForm.entries.reduce((acc, entry) => {
                const key = entry.type === "header" ? `header_${entry.key}` : entry.key;
                acc[key] = entry.value === "Custom Value" ? entry.customValue : entry.value;
                return acc;
            }, {});

            if (this.dialogMode === "add") {
                const response = await this.$http.post("/custom-webhooks", payload);
                const webhook = this.parseWebhooks(response.data.webhook);
                this.webhooks.push(webhook);
            } else if (this.dialogMode === "edit") {
                const response = await this.$http.put(`/custom-webhooks/${payload.id}`, payload);
                const index = this.webhooks.findIndex((w) => w.id === payload.id);
                if (index !== -1) this.webhooks.splice(index, 1, this.parseWebhooks(response.data.webhook));
            }

            if (this.dialog) {
                this.dialog = false; // Close dialog only for manual additions/edits
            }
        },
    },
};
</script>